export const API_URL = "https://api.secondstage.app"
export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#F9BD99', '#30B6B5'],
        home_question: ['#30B6B5', '#30B6B5'],
        applausometro: ['#E5634A', '#E5448A'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#F9BD99', '#30B6B5'],
        wordcloud: ['#F9BD99', '#F9BD99'],
        qna: ['#F9BD99', '#30B6B5'],
    },
    primary: '#F9BD99',
    secondary: '#30B6B5',
    accent: '#F9BD99',
    text: '#eceff1',
    background:"#233988",
    poll:{
        default:"#414272",
        answered:"#F9BD99",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}