import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import pleshDark from "../../../theme/pleshDark";
import {THEME_CONFIG} from "../../../config";

const SentimentQuestion = ({tipo, sendAnswer, answer, theme}) => {
    const {gradients} = THEME_CONFIG

    console.log()
    return (
        <Grid sx={{position: 'relative'}} container justifyContent={"center"} alignItems={"center"}
              style={{textAlign: 'center', height: '100%', position:'relative'}} spacing={5}>
            <Grid item xs={12} height={'50%'}>
                <Button onClick={() => sendAnswer('yes')} variant={'sentiment'} risposta={'daccordo'} style={{opacity: answer==='no' && '0.2', background: gradients.sentiment[0]}}>
                    <Typography variant={'h5'} fontWeight={'bold'} color={tipo === 'clickTo' ? '#263238' : ''}>A</Typography>
                </Button>
            </Grid>
            <Grid item xs={12} height={'50%'}>
                <Button onClick={() => sendAnswer('no')} variant={'sentiment'} risposta={'nonDaccordo'} style={{opacity: answer==='yes' && '0.2', background: gradients.sentiment[1]}}>
                    <Typography variant={'h5'} fontWeight={'bold'}>B</Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default SentimentQuestion
