import React, {useEffect, useState} from "react";
import {
    Backdrop, Box,
    Button, capitalize,
    Card,
    CircularProgress, Collapse,
    Container, FormControl, InputLabel, MenuItem, Select,
    Snackbar,
    Stack, TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {TOOLPRESS_API} from "../../../config";
import {LogoBox} from "../../VoteContent";
import logo from "../../../assets/evento/CSS Logo 2024_Neg.png"

export default function ToolpressQuestion({elementPlaying}) {
    const {id} = useParams()
    const [fields, setFields] = useState([])
    let navigate = useNavigate()
    const [values, setValues] = useState({
        domanda: '',
        Relatore: ''
    })
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        axios.get(`${TOOLPRESS_API}/conference/${elementPlaying.conferenza}/columns`)
            .then(response => {
                const obj = {}
                setFields(response.data)
                response.data.map(r => obj[r] = '')
                setValues({...values, ...obj})
                setLoading(false)
            })
            .catch(() => {

            })
    }, [])

    const handleChangeValue = (e, key) => {
        setValues({...values, [key]: e.target.value})
    }

    const postQuestions = async () => {
        setSending(true)
        await axios.post(`${TOOLPRESS_API}/question/${elementPlaying.conferenza}`, {question: values})
        setSending(false)
        setValues(prevState => ({...prevState, domanda: '', Relatore: ''}))
    }


    const sendQuestion = (e) => {
        e.preventDefault()

        postQuestions()
            .then(() => {
                setSuccess(true)
                setOpen(true)
                setTimeout(() => setOpen(false),7000)
            })
            .catch(() => setError(true))

    }


    return(
        <Box>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading || sending}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box my={5} display={"flex"} justifyContent={'center'}>
                <img src={logo} width={'70%'}/>
            </Box>
            <Collapse in={open}>
                <Typography gutterBottom textAlign={'center'}>
                    Grazie per la tua partecipazione.
                    <br/>
                    Abbiamo registrato la tua domanda, riceverai la risposta dopo l’evento.
                </Typography>
            </Collapse>
            <form style={{padding: '1rem 1rem', textAlign: 'left'}} onSubmit={sendQuestion}>
                <Stack direction={"column"} spacing={4}>
                    <FormControl fullWidth variant={'standard'}>
                        <InputLabel id="demo-simple-select-label">Relatore:</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values['Relatore']}
                            label="Relatore:"
                            required
                            onChange={(e) => handleChangeValue(e, 'Relatore')}
                        >
                            <MenuItem value={'Domanda generica'}><i>Domanda generica</i></MenuItem>
                            <MenuItem value={'Nando Pagnoncelli'}>Nando Pagnoncelli</MenuItem>
                            <MenuItem value={'Stefano Orlandi'}>Stefano Orlandi</MenuItem>
                            <MenuItem value={'Marco Pellizzoni'}>Marco Pellizzoni</MenuItem>
                            <MenuItem value={'Giangiacomo Ibba'}>Giangiacomo Ibba</MenuItem>
                            <MenuItem value={'Daniele Fornari'}>Daniele Fornari</MenuItem>
                            <MenuItem value={'Nando Barbarossa'}>Nando Barbarossa</MenuItem>
                            <MenuItem value={'Federico Capeci'}>Federico Capeci</MenuItem>
                            <MenuItem value={'Alessandro Pastore'}>Alessandro Pastore</MenuItem>
                            <MenuItem value={'Valentina Holzner'}>Valentina Holzner</MenuItem>
                        </Select>
                    </FormControl>
                    {/*fields.map(f =>
                        <Box>
                            <TextField sx={{width: '100%'}} variant={"standard"}
                                       label={capitalize(f)}
                                       required
                                       value={values[f]} onChange={(e) => handleChangeValue(e, f)}
                            />
                        </Box>
                    )*/}
                    <Box sx={{width: '100%'}}>
                        <TextField
                            fullWidth
                            label={'Il tuo nome'}
                            variant={"outlined"}
                            onChange={(e) => handleChangeValue(e, 'Nome')}
                            value={values['Nome']}
                        />
                    </Box>
                    <Box sx={{width: '100%'}}>
                        <TextField
                            required
                            fullWidth
                            label={'Scrivi la tua domanda'}
                            variant={"outlined"}
                            onChange={(e) => handleChangeValue(e, 'domanda')}
                            value={values['domanda']}
                            multiline
                            minRows={4}
                        />
                    </Box>
                    <Box sx={{textAlign: 'center'}}>
                        <Button sx={{my: 2}} variant={"default"} type={"submit"}>
                            {success ? "Invia un'altra domanda" : 'Invia domanda'}
                        </Button>
                    </Box>
                </Stack>
            </form>
        </Box>
    )
}
